<!-- MyChart.vue -->
<template>
    <div>
        
        <b-card class="count-report-sec">
            <div class="v-card-text" style="align-self: center;">
                <h4 class="text-h4 m-0 b-0"><strong>Incomes</strong> </h4>
            </div>
            <div class="v-card-text" style="align-self: center;">
                <router-link to="/report/transaction/sales-reports" class="custom-link">
                <span>Show Table</span>
                    <b-img :src="require('@/assets/images/icons/chevron-down.svg')" alt="Meeting Pic" />
                </router-link>

            </div>
        </b-card>

        <b-card>
    
            <div class="d-flex justify-content-center justify-content-sm-start my-1" style="flex-wrap: wrap">
                
                <!-- month picker -->
                <div class="mt-lg-0 mt-md-0 mt-1">
                    <month-picker-input class="inpute-range" :class="activeMonthInput ? 'month-picker-active-class' : ''"  style="z-index: 1;" :default-month="date.getMonth() + 1"
                        :default-year="date.getFullYear()" :max-date="currentYearEnd" :min-date="currentYearStart"
                        :input-pre-filled="true" :default="date" :show="{ month: true, year: false, date: false }"
                        v-model="selectedMonth" date-format="%n"></month-picker-input>
                </div>
    
                <!-- quarter select -->
                <div class="mt-lg-0 mr-1 mt-md-0 mt-1">
                    <b-dropdown v-ripple.400="'rgba(113, 102, 240, 0.15)'" v-model="quarterSelect"
                    :class="activeQuarterInput ? 'active-class' : 'drop-down-range'"  size="sm" :text="quarterSelect" variant="flat-primary">
                        <template #button-content>
                            {{ quarterSelect ? quarterSelect : 'Select Q' }}
                        </template>
                        <b-dropdown-item @click="changeQuarter(item)" v-for="(item, index) in quarterOptions" :key="index">
                            {{ item.text }}
                        </b-dropdown-item>
                    </b-dropdown>
    
                </div>
    
                <!-- year select -->
                <div class="mt-lg-0 mr-1 mt-md-0 mt-1">
                    <b-dropdown v-ripple.400="'rgba(113, 102, 240, 0.15)'" v-model="yearSelect" :class="activeYearInput ? 'active-class' : 'drop-down-range'"
                        size="sm" :text="yearSelect" variant="flat-primary">
    
                        <template #button-content>
                            {{ yearSelect ? yearSelect : 'Select Y' }}
                        </template>
    
                        <b-dropdown-item @click="changeYear(item)" v-for="(item, index) in yearOptions" :key="index">
                            {{ item }}
                        </b-dropdown-item>
                    </b-dropdown>
                </div>
    
                <!-- date range select -->
                <div class="mt-lg-0 mr-1 mt-md-0 mt-1">
                    <flat-pickr v-model="selectedDateRange" :config="flatpickrOptions" class="form-control" :class=" activeRangeInput ?'year-picker-active-class' : ''"  />
                </div>
    
            </div>
    
            <div ref="chart" style="width: 100%; height: 400px"></div>
        </b-card>

    </div>
</template>
    
<script>
import { MonthPickerInput } from 'vue-month-picker'
import * as echarts from 'echarts'
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import 'flatpickr/dist/flatpickr.css';
import FlatPickr from 'vue-flatpickr-component';

export default {
    props: {
        chartsData: []
    },
    components: {
        flatPickr: FlatPickr,
        MonthPickerInput,
    },
    data() {
        return {
            date: new Date(),
            activeYearInput: false,
            activeMonthInput: true,
            activeRangeInput: false,
            activeQuarterInput: false,
            selectedMonth: null,
            currentYearStart: new Date(new Date().getFullYear(), 0, 1), // Start of the current year
            currentYearEnd: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
            selectedDateRange: [new Date(new Date().getFullYear(), new Date().getMonth(), 1), // First day of the current month
            new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0) // Last day of the current month
            ],
            flatpickrOptions: {
                mode: 'range',
                dateFormat: 'Y-m-d',

                // defaultDate: [
                //     new Date(new Date().getFullYear(), new Date().getMonth(), 1), // First day of the current month
                //     new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0) // Last day of the current month
                // ],

            },
            yearSelect: null,
            yearOptions: ["2024", "2023", "2022"],
            quarterOptions: [
                {
                    'text': "Q1",
                    'value': "1",

                },
                {
                    'text': "Q2",
                    'value': "2",

                },
                {
                    'text': "Q3",
                    'value': "3",

                },
                {
                    'text': "Q4",
                    'value': "4",

                },
            ],
            quarterSelect: null,
            dateDaily: null,
            showDismissibleAlert: false,
            errors_back: [],
            chart: null,
        }
    },
    async created() {
    },
    mounted() {
        this.runChart();
    },
    watch: {
        selectedDateRange(newDateRange, oldDateRange) {
            if (newDateRange !== oldDateRange) {
                const dateStrings = newDateRange.split(' to ');

                // Check if there are two date strings
                if (dateStrings.length === 2) {
                    // Call your API function with the updated month
                    this.getIncomesReport('range', dateStrings);
                }
            }

        },
        selectedMonth(newMonth, oldMonth) {
            const month = (newMonth.from.getMonth() + 1).toString().padStart(2, '0');
            const year = newMonth.from.getFullYear();


            if (newMonth !== oldMonth) {
                // Call your API function with the updated month
                this.getIncomesReport('month', year + '-' + month);
            }
        },
        chartsData: {
            handler(newData, oldData) {
                if (newData !== oldData) {
                    this.runChart();
                }
            },
            deep: true,
        },
    },
    methods: {
        async getIncomesReport(requestType, data) {
            const requestData = {}
            if (requestType == 'month') {
                requestData.month = data
                this.activeMonthInput = true
                this.activeYearInput = false
                this.activeQuarterInput = false
                this.activeRangeInput = false
            } else if (requestType == 'quarter') {
                requestData.quarter = data
                this.activeMonthInput = false
                this.activeYearInput = false
                this.activeQuarterInput = true
                this.activeRangeInput = false

            } else if (requestType == 'year') {
                requestData.year = data
                this.activeMonthInput = false
                this.activeYearInput = true
                this.activeQuarterInput = false
                this.activeRangeInput = false

            } else if (requestType == 'range') {
                requestData.range = {
                    from: data[0],
                    to: data[1]
                };
                this.activeMonthInput = false
                this.activeYearInput = false
                this.activeQuarterInput = false
                this.activeRangeInput = true
            }
            await axios
                .post('/incomes/report/createdAt/get', requestData)
                .then((result) => {
                    // this.chartsData = result.data.data
                    this.$emit('update-incomes-charts-data', result.data.data);

                })
                .catch((err) => {
                    this.errors_back.length = 0
                    if (err.response.data.data != null) {
                        if (this.isString(err.response.data.data)) {
                            this.errors_back.push({
                                error: err.response.data.data,
                            })
                        } else {
                            this.errors_back = err.response.data.data
                        }
                        this.showDismissibleAlert = true
                    } else {
                        this.errors_back = []
                        this.errors_back.push({
                            error: 'internal server error',
                        })
                        this.showDismissibleAlert = true
                    }
                })
        },
        runChart() {
            // Initialize ECharts chart
            this.chart = echarts.init(this.$refs.chart)

            if (this.chartsData && this.chartsData.length > 0) {

                // Extract data for each count type
                // const totalData = this.chartsData.map((item) => [
                //   item.created_group,
                //   item.total_count,
                // ])
                const portalMargin = this.chartsData.map((item) => [
                    item.created_group,
                    item.portal_total_margin,
                ])
                const instoreMargin = this.chartsData.map((item) => [
                    item.created_group,
                    item.in_store_total_margin,
                ])
                const paymentSessionMargin = this.chartsData.map((item) => [
                    item.created_group,
                    item.payment_session_total_margin,
                ])

                // Configure the chart options
                const option = {

                    tooltip: {
                        trigger: 'axis',
                    },
                    legend: {
                        data: [
                            'Portal Margin',
                            'In-store Margin',
                            'C2C Margin',
                        ],
                    },
                    xAxis: {
                        type: 'category',
                        nameLocation: 'middle',
                        data: this.chartsData.map((item) => item.created_group),
                        splitLine: {  
                            show: true,
                            lineStyle: {
                                type: 'solid',
                                color: '#ebe9f1',  
                            },
                        },
                    },
                    yAxis: {
                        name: 'Amount Egp',
                        splitLine: {  
                            show: true,
                            lineStyle: {
                                type: 'solid',
                                color: '#ebe9f1',  
                            },
                        },
                        
                    },
                    series: [
                        {
                            name: 'Portal Margin',
                            type: 'line',
                            smooth: true,
                            data: portalMargin,
                            lineStyle: {
                                color: '#FFE802',
                            },
                            itemStyle: {
                                color: '#FFE802',
                            },
                        },
                        {
                            name: 'In-store Margin',
                            type: 'line',
                            data: instoreMargin,
                            smooth: true,
                            lineStyle: {
                                color: '#666EE8',
                            },
                            itemStyle: {
                                color: '#666EE8',
                            },
                        },
                        {
                            name: 'C2C Margin',
                            type: 'line',
                            smooth: true,
                            data: paymentSessionMargin,
                            lineStyle: {
                                color: '#FF4961',
                            },
                            itemStyle: {
                                color: '#FF4961',
                            },
                        },
                    ],
                }
                // Set the chart options
                this.chart.setOption(option)
            } else {
                // If chartsData is empty, show an empty chart or handle it as needed
                this.chart.clear();
            }
        },
        changeQuarter(quarter) {
            this.quarterSelect = quarter.text
            this.getIncomesReport('quarter', quarter.value);
        },
        changeYear(year) {
            this.yearSelect = year
            this.getIncomesReport('year', year);

        },
    },
    beforeDestroy() {
        // Dispose of the chart instance when the component is destroyed
        if (this.chart) {
            this.chart.dispose()
        }
    },
}
</script>
    
<style>
.drop-down-range {
    padding: 3px 0px !important;
    background-color: #F2F2F2 !important;
}

.drop-down-range button {
    color: #3f4144;
}

.btn-flat-primary.dropdown-toggle::after {
    filter: grayscale(1);
}

.inpute-range input {
    padding: 8px 6px !important;
}

.monthSelect {
    background-color: #7367F0;
}

.year-picker .month-picker-input {

background-color: #F2F2F2 !important;
border: 1px solid #ebe9f1 !important;
width: 48%;
}

.drop-down-range {
padding: 3px 0px !important;
background-color: #F2F2F2 !important;
}

.drop-down-range button {
color: #3f4144;
}

.btn-flat-primary.dropdown-toggle::after {
filter: grayscale(1);
}

.inpute-range input {
padding: 8px 6px !important;
}

.monthSelect {
background-color: #7367F0;
}

.count-report-sec .card-body {
display: flex;
flex-direction: row;
justify-content: space-between;
}

.custom-link {
display: flex;
align-items: center;
justify-content: space-between;
text-decoration: none;
background-color: #FFF0E1;
/* width: 79.21px;
  height: 20px; */
font-size: 12px;
font-weight: 500;
border-radius: 2.63px;
padding: 7px 10.37px;
gap: 5.26px;
color: #000;
/* Change text color as needed */
}

.custom-link span {
color: #DB4A06;

}

.arrow-icon {
font-size: 12px;
/* Adjust font size as needed */
}

.active-class {
padding: 3px 0px !important;
background-color: #7367f0 !important;
color: #fff !important;
}

.month-picker-active-class input {
background-color: #7367f0 !important;
color: #fff !important;
border: none !important;

}

.active-class button {
color: #fff !important;
}

.year-picker-active-class {
background-color: #7367f0 !important;
color: #fff !important;
}

/* Your component styles go here */
</style>
  
  
  
     