<template >
    <div v-if="authPermissions">
        <HighLevelHome v-if="authPermissions.includes('high-level-admin-home')"/>
        <OperationHome v-else/>
        <!-- <OperationHome /> -->
    </div>
</template>
  
<script>

import HighLevelHome from './HighLevelHome.vue'
import OperationHome from './OperationHome.vue'
export default {
    components: {
        HighLevelHome,
        OperationHome

    },

    data() {
        return {
            authPermissions: [],
        }
    },

    watch: {

    },


    async created() {
        await this.setPermission()

    },

    methods: {
        async setPermission(){
             this.authPermissions = this.$store.state.authPermissions
        }

    },
}
</script>
  
<style lang="scss">
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
  
<style lang="css">
.month-picker__container,
.month-picker--default {
    background-color: #fff;
}

.month-picker {
    font-weight: 200;
    box-sizing: border-box;
    flex: 1;
    width: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-family: sans-serif;
    overflow: hidden;
    justify-content: space-evenly;
    align-content: space-around;
}

.dountRevenu {
    height: 75% !important;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}
</style>
  