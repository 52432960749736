<template>
    <b-card no-body class="card-browser-states" style="height: 590px !important; overflow-y: auto;">
        <b-card-header class="mb-0 pb-0">
            <div>
                <b-card-title :style="color" style="font-weight: bold;"> {{ title }}</b-card-title>

            </div>
            <div class="v-card-text" style="align-self: center;" v-if="showListingPermission">
                <router-link :to="linkLink" class="custom-link">
                    <span>Show Table</span>
                    <b-img :src="require('@/assets/images/icons/chevron-down.svg')" alt="Meeting Pic" />
                </router-link>

            </div>
        </b-card-header>

        <!-- body -->
        <b-card-body>
            <div v-for="(item, index) in listingArrayData" :key="index" class="my-2 d-flex">

                <div v-if="item.cover">
                    <b-media class="mb-1">
                        <img class="card-img" ref="previewEl" :src="item.cover" style="width: 60px;" />
                    </b-media>
                </div>
                <div v-else>
                    <b-img :src="require('@/assets/images/icons/success_report_icon.svg')" alt="Meeting Pic"
                        style="width: 60px;" />
                </div>

                <a :href="getUrl(item)">
                    <div class="mx-1 card-description">
                        <h5 class="font-weight-bold text-body-heading h5">{{ item.name }}<b-img
                                :src="require('@/assets/images/icons/shar_icon.svg')"
                                style="width: 10px;margin-left: 4px;" /></h5>
                        <p class=" text-body-heading font-weight-bold mb-0" style="color: #28c76f;font-size: 13px;">{{ item.sku }}
                            <a :href=" showVendorPermission ? item.user_id ? '/User/Show/' + item.user_id : '/Vendor/Show/' + item.vendor_id : ''">
                                <b-badge variant="light-success">{{ item.seller_name }}
                                    <b-img :src="require('@/assets/images/icons/shar_icon.svg')"
                                        style="width: 10px;margin-left: 4px;" />
                                </b-badge>
                            </a>
                        </p>

                        <p class=" text-body-heading " style="font-size: 11px;">{{ formatDate(item.created_at) }}</p>


                    </div>
                </a>
                <div style="margin-left: auto;" class="dropdown-icon" v-if="showListingPermission">
                    <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>

                        <template v-slot:button-content>
                            <feather-icon icon="MoreVerticalIcon" size="20" class="text-body" />
                        </template>
                        <b-dropdown-item :href="getUrl(item)" target="_blank">
                            <feather-icon icon="EyeIcon" class="mr-50" />
                            <span>Show</span>
                        </b-dropdown-item>


                    </b-dropdown>
                </div>
            </div>
        </b-card-body>
        <!--/ body -->
    </b-card>
</template>

<script>

import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";
/* eslint-disable global-require */
const $trackBgColor = "#e9ecef";
export default {
    props: ["title", "listingArrayData", "chartData", "color", "listingType", 'linkLink'],
    components: {

        VueApexCharts,
    },
    created(){

        this.authPermissions = this.$store.state.authPermissions
        if (this.authPermissions.includes('vendor')) {
            this.showVendorPermission = true
        }
        if(this.listingType == 'products'){
            if (this.authPermissions.includes('variant')) {
                this.showListingPermission = true
            }
        }else if(this.listingType == 'reports'){
            if (this.authPermissions.includes('diagnostic')) {
                this.showListingPermission = true
            }

        }
    },
    data() {
        return {
            showVendorPermission:false,
            showListingPermission:false,
            reportShowUrl: "/diagnostic/reports/show/",
            usedProductShowUrl: "/Used/ShowProduct/variant/show/",
            newProductShowUrl: "/New/ShowProduct/variant/show/",
            // chartData: [],
            // chartData: {},
            authPermissions: null,
            chartColor: [
                $themeColors.primary,
                $themeColors.warning,
                $themeColors.secondary,
                $themeColors.info,
                $themeColors.danger,
            ],
            // chartSeries: [],
            // listingArrayData: [],
            chart: {
                series: [],
                options: {
                    grid: {
                        show: false,
                        padding: {
                            left: -15,
                            right: -15,
                            top: -12,
                            bottom: -15,
                        },
                    },
                    colors: [$themeColors.primary],
                    plotOptions: {
                        radialBar: {
                            hollow: {
                                size: "22%",
                            },
                            track: {
                                background: $trackBgColor,
                            },
                            dataLabels: {
                                showOn: "always",
                                name: {
                                    show: false,
                                },
                                value: {
                                    show: false,
                                },
                            },
                        },
                    },
                    stroke: {
                        lineCap: "round",
                    },
                },
            },
        };
    },
    methods: {
        formatDate(dateTimeString) {
            const date = new Date(dateTimeString);

            // Format month
            const monthNames = [
                "Jan", "Feb", "Mar", "Apr", "May", "Jun",
                "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
            ];
            const month = monthNames[date.getMonth()];

            // Format day with suffix
            const day = this.addSuffix(date.getDate());

            // Format year
            const year = date.getFullYear();

            // Format time
            const hours = date.getHours();
            const minutes = date.getMinutes();
            const time = this.formatTime(hours, minutes);

            return `${month} ${day} ${year} | ${time}`;
        },

        addSuffix(day) {
            if (day >= 11 && day <= 13) {
                return `${day}th`;
            }

            switch (day % 10) {
                case 1:
                    return `${day}st`;
                case 2:
                    return `${day}nd`;
                case 3:
                    return `${day}rd`;
                default:
                    return `${day}th`;
            }
        },

        formatTime(hours, minutes) {
            const period = hours >= 12 ? "PM" : "AM";
            const formattedHours = hours % 12 || 12;
            const formattedMinutes = minutes.toString().padStart(2, "0");

            return `${formattedHours}:${formattedMinutes} ${period}`;
        },

        getDaySuffix(day) {
            if (day >= 11 && day <= 13) {
                return 'th';
            }

            switch (day % 10) {
                case 1:
                    return 'st';
                case 2:
                    return 'nd';
                case 3:
                    return 'rd';
                default:
                    return 'th';
            }
        },
        openLinkInNewTab(item) {
            var url = ''
            if (this.listingType == 'products') {
                url = item.is_new ? '/New/ShowProduct/variant/show/' + item.id : '/Used/ShowProduct/variant/show/' + item.id
            } else if (this.listingType == 'reports') {

                url = '/diagnostic/reports/show/' + item.id
            }
            const fullPath = this.$router.resolve({ path: url }).href;
            console.log(fullPath)
            return;
            window.open(fullPath, '_blank');
        },
        getUrl(item) {
            var url = ''
            if(this.showListingPermission){

                if (this.listingType == 'products') {
                    url = item.is_new ? '/New/ShowProduct/variant/show/' + item.id : '/Used/ShowProduct/variant/show/' + item.id
                } else if (this.listingType == 'reports') {
    
                    url = '/diagnostic/reports/show/' + item.id
                }
            }


            return url;

        },

    },

    // created() {
    //     for (let i = 0; i < this.listingArrayData.length; i += 1) {
    //         const chartClone = JSON.parse(JSON.stringify(this.chart));
    //         chartClone.options.colors[0] = this.chartColor[i];
    //         chartClone.series[0] = this.chartSeries[i];
    //         this.chartData.push(chartClone);
    //     }
    // },
};
</script>
<style>
.card-img {
    border: 1px solid transparent;
    border-radius: 8px;
    padding: 13px;
    background-color: #f2f2f2;
}

.card-description {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.dropdown-icon button {
    padding-right: 0px;

}

.card-description:hover {
    color: #28c66f;
}

.card-browser-states {
    /* scrollbar-width: thin; */
    scrollbar-color: #28c66f #e5f8ed;
    scroll-behavior: smooth;
}


.card-browser-states::-webkit-scrollbar-track {
    background-color: #e5f8ed;
}
</style>
