<!-- MyChart.vue -->
<template>
    <b-card class="count-report-sec"
        :style="{ 'border': '1px solid ' + borderColor, 'background-color': backgroundColor }">
        <div class="v-card-text">
            <h3 class="text-h3" v-if="card_mnumber != null"><strong :style="{'background-color' : borderColor}" style="border-radius: 50%;padding: 3px 9px; color: white !important;">{{ formatPrice(card_mnumber) }} </strong> {{ currency }}</h3>
            <div class="spinner-border text-dark" role="status" v-else>
                <span class="sr-only">Loading...</span>
            </div>
            <p class="text-sm text-disabled my-1 mb-0" :style="{'color' : borderColor , } ">{{ card_text }}</p>
        </div>
        <div class="v-card-text" style="align-self: center;">
            <b-img :src="require('@/assets/images/icons/' + card_icon)" alt="Meeting Pic" />
        </div>
    </b-card>
</template>
   
<script>

import { kFormatter } from "@core/utils/filter";
export default {
    props: {
        card_mnumber: null,
        card_text: null,
        card_icon: null,
        currency: null,
        borderColor: null,
        backgroundColor: null,
    },
    components: {
    },
    data() {
        return {
        }
    },
    methods: {
        formatPrice(value) {
            if (this.currency) {

                let val = (value / 1).toFixed(2).replace('.', ',')
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            } else {
                return value
            }
        }


    },
}
</script>
   
<style>
.drop-down-range {
    padding: 3px 0px !important;
    background-color: #F2F2F2 !important;
}

.drop-down-range button {
    color: #3f4144;
}

.btn-flat-primary.dropdown-toggle::after {
    filter: grayscale(1);
}

.inpute-range input {
    padding: 8px 6px !important;
}

.monthSelect {
    background-color: #7367F0;
}

.count-report-sec .card-body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.text-disabled{
    font-size: 15px;
}

.count-report-sec{
  max-height: 130px;
}

/* Your component styles go here */
</style>
 
 
 
    