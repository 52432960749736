<!-- MyChart.vue -->
<template>
    <b-row>

        <b-col cols="12" md="12">
            <ProductsCountSectionCard :totalProductsCountsData="variantsCountReport"
                @update-products-counts-data="updateProductsCountsData" />
                
        </b-col>

        <b-col cols="12" md="4">
            <CardListingReport
            :linkLink="'/diagnostic/reports'"
            class="h-100"
            title="Reports"
            :listingArrayData="reports"
            :color="'color:#000'"
            :listingType="'reports'"
          />


        </b-col>

        <b-col cols="12" md="4">
            <CardListingReport
            :linkLink="'/products/main-table'"
            class="h-100"
            title="Products"
            :listingArrayData="products"
            :color="'color:#000'"
            :listingType="'products'"
          />


        </b-col>
        
        <b-col cols="12" md="4">
            <CardListingReport
            :linkLink="'/ads/main-table'"
            class="h-100"
            title="Ads"
            :listingArrayData="ads"
            :color="'color:#000'"
            :listingType="'products'"
          />


        </b-col>
        

    </b-row>
</template>
    
<script>

import ProductsCountSectionCard from "../Components/charts/ProductsCountSectionCard.vue"
import CardListingReport from "../Components/charts/CardListingReport.vue"
import { MonthPickerInput } from 'vue-month-picker'

export default {
    components: {
        ProductsCountSectionCard,
        MonthPickerInput,
        CardListingReport,
    },
    data() {
        return {
            date: new Date(),
            quarterSelect: "Q1",
            dateDaily: null,
            showDismissibleAlert: false,
            errors_back: [],
            variantsCountReport: {},
            responseData: [],
            products:[],
            ads:[],
            reports:[],


        }
    },
    async created() {
        await this.getProductsReport()
        // this.run()


    },
    methods: {
        updateProductsCountsData(newData) {
            this.variantsCountReport = newData;
        },
        async getProductsReport() {
            await axios
                .post('adminHomeOperationLevel')
                .then((result) => {

                    this.responseData = result.data.data
                    // console.log(this.responseData)
                    this.variantsCountReport = result.data.data.variantsCountReport
                    this.products = result.data.data.getLastVariants
                    this.ads = result.data.data.getLastAds
                    this.reports = result.data.data.getLastReports



                })
                .catch((err) => {
                    // console.log(err)
                    this.errors_back.length = 0
                    if (err.response.data.data != null) {
                        if (this.isString(err.response.data.data)) {
                            this.errors_back.push({
                                error: err.response.data.data,
                            })
                        } else {
                            this.errors_back = err.response.data.data
                        }
                        this.showDismissibleAlert = true
                    } else {
                        this.errors_back = []
                        this.errors_back.push({
                            error: 'internal server error',
                        })
                        this.showDismissibleAlert = true
                    }
                })
        },

    },
}
</script>
    
<style>
.drop-down-range {
    padding: 3px 0px !important;
    background-color: #F2F2F2 !important;
}

.drop-down-range button {
    color: #3f4144;
}

.btn-flat-primary.dropdown-toggle::after {
    filter: grayscale(1);
}

.inpute-range input {
    padding: 8px 6px !important;
}

.monthSelect {
    background-color: #7367F0;
}

.count-report-sec .card-body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

/* Your component styles go here */
</style>
  
  
  
     