<!-- MyChart.vue -->
<template>
    <b-row>
        <!-- <b-col cols="12" md="12">
            <b-card>
                <div class="d-flex justify-content-center justify-content-sm-start my-1" style="flex-wrap: wrap">
                    <div class="mt-lg-0 mt-md-0 mt-1">
                        <month-picker-input class="inpute-range" :class="activeMonthInput ? 'month-picker-active-class' : ''" style="z-index: 1;" :default-month="date.getMonth() + 1"
                            :default-year="date.getFullYear()" :max-date="currentYearEnd" :min-date="currentYearStart"
                            :input-pre-filled="true" :default="date" :show="{ month: true, year: false, date: false }"
                            v-model="selectedMonth" date-format="%n"></month-picker-input>
                    </div>

                    <div  class="mt-lg-0 mr-1 mt-md-0 mt-1">
                        <b-dropdown v-ripple.400="'rgba(113, 102, 240, 0.15)'" v-model="quarterSelect"
                        class="border  "
                        :class="activeQuarterInput ? 'active-class' : 'drop-down-range'" size="sm" :text="quarterSelect" variant="flat-primary">
                            <template #button-content>
                                {{ quarterSelect ? quarterSelect : 'Select Q' }}
                            </template>
                            <b-dropdown-item @click="changeQuarter(item)" v-for="(item, index) in quarterOptions"
                                :key="index">
                                {{ item.text }}
                            </b-dropdown-item>
                        </b-dropdown>

                    </div>

                    <div  class="mt-lg-0 mr-1 mt-md-0 mt-1">
                        <b-dropdown v-ripple.400="'rgba(113, 102, 240, 0.15)'" v-model="yearSelect"
                        class="border "
                        :class="activeYearInput ? 'active-class' : 'drop-down-range'"   size="sm" :text="yearSelect" variant="flat-primary">

                            <template #button-content>
                                {{ yearSelect ? yearSelect : 'Select Y' }}
                            </template>

                            <b-dropdown-item @click="changeYear(item)" v-for="(item, index) in yearOptions" :key="index" >
                                {{ item }}
                            </b-dropdown-item>
                        </b-dropdown>
                    </div>
                    <div  class="mt-lg-0 mr-1 mt-md-0 mt-1">
                        <flat-pickr v-model="selectedDateRange" :config="flatpickrOptions" class="form-control" :class=" activeRangeInput ?'year-picker-active-class' : ''" />
                    </div>
                </div>

            </b-card>
        </b-col> -->
        <!-- Start First Col -->
        <b-col cols="12" md="3" sm="6">
            <TotalCardSection :card_mnumber="totalEnabledPortalProducts" card_text="Products In Stock"
                card_icon="produts_icon.svg"/>
        </b-col>
        <b-col cols="12" md="3" sm="6">
            <TotalBorderCardSection :card_mnumber="totalReviewPortalProducts" card_text="Products To Review"
                card_icon="color_products_icon.svg" :borderColor="'#28c76f'" :backgroundColor="'#f6fffa'" />
        </b-col>
        <b-col cols="12" md="3" sm="6">
            <TotalCardSection :card_mnumber="totalEnabledAds" card_text="Ads In Stock"
                card_icon="ads_icon.svg" />

        </b-col>
        <b-col cols="12" md="3" sm="6">
            <TotalBorderCardSection :card_mnumber="totalReviewAds" :backgroundColor="'#f9ede2'" card_text="Ads To Review" :borderColor="'#ff5100'"
                card_icon="color_ads_icon.svg"  />
        </b-col>
        <!-- End First Col -->

        <!-- <b-col cols="12" md="12"> -->
              <hr class="w-100 border-1 border-gray mx-1" />
        <!-- </b-col> -->
    </b-row>
</template>
    
<script>
import TotalCardSection from "./TotalCardSection.vue"
import TotalBorderCardSection from "./TotalBorderCardSection.vue"
import { MonthPickerInput } from 'vue-month-picker'
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import 'flatpickr/dist/flatpickr.css';
import FlatPickr from 'vue-flatpickr-component';

export default {
    props: {
        totalProductsCountsData: []
    },
    components: {
        flatPickr: FlatPickr,
        MonthPickerInput,
        TotalCardSection,
        TotalBorderCardSection,
    },
    data() {
        return {
            date: new Date(),
            activeYearInput: false,
            activeMonthInput: true,
            activeRangeInput: false,
            activeQuarterInput: false,
            selectedMonth: null,
            currentYearStart: new Date(new Date().getFullYear(), 0, 1), // Start of the current year
            currentYearEnd: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
            selectedDateRange: [new Date(new Date().getFullYear(), new Date().getMonth(), 1), // First day of the current month
            new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0) // Last day of the current month
            ],
            flatpickrOptions: {
                mode: 'range',
                dateFormat: 'Y-m-d',
            },
            yearSelect: null,
            yearOptions: ["2024", "2023", "2022"],
            quarterOptions: [
                {
                    'text': "Q1",
                    'value': "1",

                },
                {
                    'text': "Q2",
                    'value': "2",

                },
                {
                    'text': "Q3",
                    'value': "3",

                },
                {
                    'text': "Q4",
                    'value': "4",

                },
            ],
            quarterSelect: null,
            dateDaily: null,
            showDismissibleAlert: false,
            errors_back: [],
            totalEnabledPortalProducts: null,
            totalReviewPortalProducts: null,
            totalEnabledAds: null,
            totalReviewAds: null,
        }
    },
    async created() {
    },
    mounted() {
        this.setData();
    },
    watch: {
        selectedDateRange(newDateRange, oldDateRange) {
            if (newDateRange !== oldDateRange) {
                const dateStrings = newDateRange.split(' to ');

                // Check if there are two date strings
                if (dateStrings.length === 2) {
                    // Call your API function with the updated month
                    this.getCountsReport('range', dateStrings);
                }
            }

        },
        selectedMonth(newMonth, oldMonth) {
            const month = (newMonth.from.getMonth() + 1).toString().padStart(2, '0');
            const year = newMonth.from.getFullYear();


            if (newMonth !== oldMonth) {
                // Call your API function with the updated month
                this.getCountsReport('month', year + '-' + month);
            }
        },
        totalProductsCountsData: {

            handler(newData, oldData) {
                if (newData !== oldData) {
                    this.setData();

                }
            },
            deep: true,
        },
    },
    methods: {
        async getCountsReport(requestType, data) {
            const requestData = {}
            if (requestType == 'month') {
                requestData.month = data
                this.activeMonthInput = true
                this.activeYearInput = false
                this.activeQuarterInput = false
                this.activeRangeInput = false
            } else if (requestType == 'quarter') {
                requestData.quarter = data
                this.activeMonthInput = false
                this.activeYearInput = false
                this.activeQuarterInput = true
                this.activeRangeInput = false

            } else if (requestType == 'year') {
                requestData.year = data
                this.activeMonthInput = false
                this.activeYearInput = true
                this.activeQuarterInput = false
                this.activeRangeInput = false

            } else if (requestType == 'range') {
                requestData.range = {
                    from: data[0],
                    to: data[1]
                };
                this.activeMonthInput = false
                this.activeYearInput = false
                this.activeQuarterInput = false
                this.activeRangeInput = true
            }
            await axios
                .post('/products/reports/counts/get', requestData)
                .then((result) => {
                    // this.totalProductsCountsData = result.data.data
                    this.$emit('update-products-counts-data', result.data.data);

                })
                .catch((err) => {
                    this.errors_back.length = 0
                    if (err.response.data.data != null) {
                        if (this.isString(err.response.data.data)) {
                            this.errors_back.push({
                                error: err.response.data.data,
                            })
                        } else {
                            this.errors_back = err.response.data.data
                        }
                        this.showDismissibleAlert = true
                    } else {
                        this.errors_back = []
                        this.errors_back.push({
                            error: 'internal server error',
                        })
                        this.showDismissibleAlert = true
                    }
                })
        },
        setData() {
            // console.log("updated" , this.totalProductsCountsData)
            // set counts data 
            if (this.totalProductsCountsData) {

                this.totalEnabledPortalProducts = this.totalProductsCountsData.portal_enabled_product 
                this.totalReviewPortalProducts = this.totalProductsCountsData.portal_review_product 
                this.totalEnabledAds = this.totalProductsCountsData.c2c_enabled_product 
                this.totalReviewAds = this.totalProductsCountsData.c2c_review_product
                
            }


        },
        changeQuarter(quarter) {
            this.quarterSelect = quarter.text
            this.getCountsReport('quarter', quarter.value);
        },
        changeYear(year) {
            this.yearSelect = year
            this.getCountsReport('year', year);

        },
    },
}
</script>
    
<style>
.drop-down-range {
    padding: 3px 0px !important;
    background-color: #F2F2F2 !important;
}

.drop-down-range button {
    color: #3f4144;
}

.btn-flat-primary.dropdown-toggle::after {
    filter: grayscale(1);
}

.inpute-range input {
    padding: 8px 6px !important;
}

.monthSelect {
    background-color: #7367F0;
}

.year-picker .month-picker-input {

    background-color: #F2F2F2 !important;
    border: 1px solid #ebe9f1 !important;
    width: 48%;
}

.drop-down-range {
    padding: 3px 0px !important;
    background-color: #F2F2F2 !important;
}

.drop-down-range button {
    color: #3f4144;
}

.btn-flat-primary.dropdown-toggle::after {
    filter: grayscale(1);
}

.inpute-range input {
    padding: 8px 6px !important;
}

.monthSelect {
    background-color: #7367F0;
}

.count-report-sec .card-body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.custom-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    background-color: #FFF0E1;
    /* width: 79.21px;
  height: 20px; */
    font-size: 12px;
    font-weight: 500;
    border-radius: 2.63px;
    padding: 7px 10.37px;
    gap: 5.26px;
    color: #000;
    /* Change text color as needed */
}

.custom-link span {
    color: #DB4A06;

}

.arrow-icon {
    font-size: 12px;
    /* Adjust font size as needed */
}

.active-class {
    padding: 3px 0px !important;
    background-color: #7367f0 !important;
    color: #fff !important;
}

.month-picker-active-class input {
    background-color: #7367f0 !important;
    color: #fff !important;
    border: none !important;

}

.active-class button {
    color: #fff !important;
}

.year-picker-active-class {
    background-color: #7367f0 !important;
    color: #fff !important;
}

/* Your component styles go here */
</style>
  
  
  
     