<!-- MyChart.vue -->
<template>
  <b-row>

    <b-col cols="12" md="12">
      <CountSectionCard :totalCountsData=" totalCountsData" @update-counts-data=" updateCountsData"/>
    </b-col>
    
    <!-- Start Chart Section -->
    <b-col cols="12" md="6">
      <ProductsCreatedCountChart :chartsData="productsCreatedCountsData" @update-products-charts-data="updateProductsChartsData"/>
    </b-col>

    <b-col cols="12" md="6">
      <SalesAmountChart :chartsData="transactionAmountData" @update-transactions-charts-data=" updateTransactionsChartsData"/>
    </b-col>

    <b-col cols="12" md="6">
      <UsersCreatedCountChart :chartsData=" usersCountsData" @update-users-charts-data="updateUsersChartsData"/>
    </b-col>

    <b-col cols="12" md="6">
      <IncomeAmountChart :chartsData=" incomeAmountData" @update-incomes-charts-data="updateIncomesChartsData"/>
    </b-col>

    <!-- End Chart Section -->
  </b-row>
</template>
  
<script>
import CountSectionCard from "../Components/charts/CountSectionCard.vue"
import ProductsCreatedCountChart from "../Components/charts/ProductsCreatedCountChart.vue"
import UsersCreatedCountChart from "../Components/charts/UsersCreatedCountChart.vue"
import SalesAmountChart from "../Components/charts/SalesAmountChart.vue"
import IncomeAmountChart from "../Components/charts/IncomeAmountChart.vue"
import { MonthPickerInput } from 'vue-month-picker'

export default {
  components: {
    CountSectionCard,
    ProductsCreatedCountChart,
    UsersCreatedCountChart,
    IncomeAmountChart,
    SalesAmountChart,
    MonthPickerInput,
  },
  data() {
    return {
      date: new Date(),
      selectedDateRange: [],
      quarterSelect: "Q1",
      dateDaily: null,
      showDismissibleAlert: false,
      errors_back: [],
      chart: null,
      responseData: [],
      productsCreatedCountsData: [],
      usersCountsData: [],
      transactionAmountData: [],
      incomeAmountData: [],
      totalCountsData: {},
      totalTransaction:null,
      totalSuccessTransaction:null,
      totalAmountPortalOrders:null,
      totalAmountInStoreOrders:null,
      totalAmountTransactions:null,
      totalAmountSuccessTransactions:null,
      totalAmountSuccessPaymentSessions:null,
      totalAmountSuccessPaymentSubscriptions:null,
    }
  },
  async created() {
    await this.getProductsReport()
    // this.run()


  },
  methods: {
    updateTransactionsChartsData(newData) {
            this.transactionAmountData = newData;
    },
    updateProductsChartsData(newData) {
            this.productsCreatedCountsData = newData;
    },
    updateUsersChartsData(newData) {
            this.usersCountsData = newData;
    },
    updateIncomesChartsData(newData) {
            this.incomeAmountData = newData;
    },
    updateCountsData(newData) {
            this.totalCountsData = newData;
    },
    async getProductsReport() {
    const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based


      await axios
        .post('adminHomeHighLevel', {
          month:  year + '-' + month
        })
        .then((result) => {
         
          this.responseData = result.data.data
          this.productsCreatedCountsData = result.data.data.variantsCreatedAtReport
          this.usersCountsData = result.data.data.usersCreatedAtReport
          this.transactionAmountData = result.data.data.totalAmountTransactionCreatedAtReport
          this.incomeAmountData = result.data.data.incomeAmountCreatedReports
          this.totalCountsData = result.data.data.totalCountsData


        })
        .catch((err) => {
          console.log(err)
          this.errors_back.length = 0
          if (err.response != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
   
  },
}
</script>
  
<style>
.drop-down-range {
  padding: 3px 0px !important;
  background-color: #F2F2F2 !important;
}

.drop-down-range button {
  color: #3f4144;
}

.btn-flat-primary.dropdown-toggle::after {
  filter: grayscale(1);
}

.inpute-range input {
  padding: 8px 6px !important;
}

.monthSelect {
  background-color: #7367F0;
}

.count-report-sec .card-body {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

/* Your component styles go here */
</style>



   